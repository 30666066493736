import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getCookie = (key) => {
    return cookies.get(key);
};

export const setCookie = (key, value) => {
    cookies.set(key, value, { path: '/' });
};

export const removeCookie = (key) => {
    return cookies.remove(key, { path: '/' });
};
