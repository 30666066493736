export const SET_DEPARTMENTS = 'DEPARTMENTS/SET_DEPARTMENTS';

export const setDepartments = (departments) => {
    // console.debug({ departments });

    return {
        type: SET_DEPARTMENTS,
        data: departments,
    };
};

export default function reducer(
    state = {
        departments: [],
    },
    action
) {
    switch (action.type) {
        case SET_DEPARTMENTS:
            return {
                ...state,
                departments: action.data,
            };

        default:
            break;
    }
    return state;
}
