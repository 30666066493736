import React, { useState } from 'react';
import clsx from 'clsx';
import { Collapse, Badge } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import SidebarUserbox from '../SidebarUserbox';
import { ChevronRight, Monitor, User, Package, Users, AlignCenter, Book, BookOpen } from 'react-feather';
import _ from 'lodash';
import { getCookie } from 'libs/cookie';

const NavTitle = ({ title }) => (
    <div className="sidebar-header">
        <span>{title}</span>
    </div>
);

const NavSimpleItem = ({ onClick, to, icon, title }) => (
    <li>
        <NavLink activeClassName="active" onClick={onClick} className="nav-link-simple" to={to}>
            <span className="sidebar-icon">{icon}</span>
            {title}
            <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                <ChevronRight />
            </span>
        </NavLink>
    </li>
);

const NavDropdownItem = ({ icon, title, items }) => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = (event) => {
        setDropdownOpen(!isDropdownOpen);
        event.preventDefault();
    };

    // console.debug({ icon, title, items });

    return (
        <li>
            <a href="#/" onClick={toggleDropdown} className={clsx({ active: isDropdownOpen })}>
                {!icon ? null : <span className="sidebar-icon">{icon}</span>}
                <span className="sidebar-item-label">{title}</span>
                <span className="sidebar-icon-indicator">
                    <ChevronRight />
                </span>
            </a>
            <Collapse isOpen={isDropdownOpen}>
                <ul>
                    {_.isUndefined(items) || _.isNull(items) || _.size(items) === 0
                        ? null
                        : _.map(items, (item, index) =>
                              _.isUndefined(item.dropdown) || _.isNull(item.dropdown) ? (
                                  !item.onClick || !item.to || !item.title ? null : (
                                      <li key={index}>
                                          <NavLink onClick={item.onClick} to={item.to}>
                                              {item.title}
                                          </NavLink>
                                      </li>
                                  )
                              ) : (
                                  item.dropdown
                              )
                          )}
                </ul>
            </Collapse>
        </li>
    );
};

const SidebarMenu = ({ setSidebarToggleMobile, sidebarUserbox }) => {
    const toggleSidebarMobile = () => setSidebarToggleMobile(false);
    const roles = getCookie('user.roles');
    return (
        <>
            <PerfectScrollbar>
                {sidebarUserbox && <SidebarUserbox />}
                <div className="sidebar-navigation">
                    <ul>
                        <NavSimpleItem
                            key={1}
                            onClick={toggleSidebarMobile}
                            to="/artists"
                            icon={<User />}
                            title="Artist Management"
                        />
                        <NavSimpleItem
                            key={2}
                            onClick={toggleSidebarMobile}
                            to="/products"
                            icon={<Package />}
                            title="Product Management"
                        />
                        <NavSimpleItem
                            key={3}
                            onClick={toggleSidebarMobile}
                            to="/articles"
                            icon={<BookOpen />}
                            title="Article Management"
                        />
                        {roles && roles.includes('super-admin') && ( 
                        <NavSimpleItem
                            key={4}
                            onClick={toggleSidebarMobile}
                            to="/infanity-blogs"
                            icon={<BookOpen />}
                            title="Infanity Blogs Management"
                        />
                        )}
                        {roles && roles.includes('super-admin') && ( 
                        <NavSimpleItem
                            key={5}
                            onClick={toggleSidebarMobile}
                            to="/categories"
                            icon={<AlignCenter />}
                            title="Category Management"
                        />
                        )}
                        {roles && roles.includes('super-admin') && ( 
                        <NavSimpleItem
                            key={6}
                            onClick={toggleSidebarMobile}
                            to="/featured"
                            icon={<BookOpen />}
                            title="Featured Management"
                        />
                        )}
                        {roles && roles.includes('super-admin') && ( 
                        <NavSimpleItem
                            key={7}
                            onClick={toggleSidebarMobile}
                            to="/contact-us"
                            icon={<BookOpen />}
                            title="Contact Us Management"
                        />
                        )}
                        {roles && roles.includes('super-admin') && ( 
                        <NavSimpleItem
                            key={8}
                            onClick={toggleSidebarMobile}
                            to="/popup"
                            icon={<BookOpen />}
                            title="Popup Management"
                        />
                        )}
                        <NavSimpleItem key={9} onClick={toggleSidebarMobile} to="/logs" icon={<Book />} title="Logs" />
                        {roles && roles.includes('super-admin') && (
                            <NavSimpleItem
                                key={10}
                                onClick={toggleSidebarMobile}
                                to="/web2logs"
                                icon={<Book />}
                                title="Web2 Account Logs"
                            />
                        )}
                    </ul>
                </div>
            </PerfectScrollbar>
        </>
    );
};

const mapStateToProps = (state) => ({
    sidebarUserbox: state.ThemeOptions.sidebarUserbox,
    sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
    departments: state.Departments.departments,
});

const mapDispatchToProps = (dispatch) => ({
    setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
