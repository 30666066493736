import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import _ from 'lodash';
import { getCookie } from '../libs/cookie';

const RequireAuth = (Component) => {
    const Wrapper = (props) => {
        // console.debug('RequireAuth.js', { props });
        // console.debug('RequireAuth.js', { location: props.location.pathname });

        const token = getCookie('token');

        const isAuthenticated = !_.isUndefined(token) && !_.isNull(token) && !_.isEmpty(token);
        if (
            props.location &&
            props.location.pathname &&
            !_.isEqual(props.location.pathname, '/login') &&
            !_.isEqual(props.location.pathname, '/recover') &&
            !_.isEqual(props.location.pathname, '/reset-password') &&
            !isAuthenticated
        ) {
            return <Redirect from={props.location.pathname} to="/login" push={true} />;
        }

        if (
            props.location &&
            props.location.pathname &&
            (_.isEqual(props.location.pathname, '/login') || _.isEqual(props.location.pathname, '/recover')) &&
            isAuthenticated
        ) {
            return <Redirect from={props.location.pathname} to="/dashboard" push={true} />;
        }

        return <Component {...props} isLoggedIn={true} />;
    };

    return withRouter(Wrapper);
};

export default RequireAuth;
