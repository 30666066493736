export const publicUrl = process.env.PUBLIC_URL;

export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export const storageBaseUrl = process.env.REACT_APP_STORAGE_BASE_URL;

export const authEndpoint = process.env.REACT_APP_AUTH_ENDPOINT;

export const coreEndpoint = process.env.REACT_APP_CORE_ENDPOINT;

export const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
export const secretKey = process.env.REACT_APP_AWS_SECRET_KEY;
export const bucketName = process.env.REACT_APP_AWS_S3_BUCKET_NAME;
export const region = process.env.REACT_APP_AWS_REGION;
