import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ClimbingBoxLoader } from 'react-spinners';
import RequireAuth from './utils/RequireAuth';

// Layout Blueprints
import { LeftSidebar, MinimalLayout } from './layout-blueprints';

// Error Pages
import PageError404 from './example-pages/PageError404';
import PageError500 from './example-pages/PageError500';
import { getCookie } from 'libs/cookie';

// Auth pages
const PageLogout = lazy(() => import('./pages/logout'));
const PageLogin = lazy(() => import('./pages/login'));
const PageRecover = lazy(() => import('./pages/recover'));
const PageResetPassword = lazy(() => import('./pages/reset-password'));

// Top pages
const PageDashboard = lazy(() => import('./pages/dashboard'));

// Core pages
const PageItem = lazy(() => import('./pages/item'));
const PageArtist = lazy(() => import('./pages/artist'));
const PageArticle = lazy(() => import('./pages/article'));
const PageInfanityBlog = lazy(() => import('./pages/blog'));
const PageCategory = lazy(() => import('./pages/category'));
const PageLogs = lazy(() => import('./pages/logs'));
const PageFeatured = lazy(() => import('./pages/featured'));
const PageContactUs = lazy(() => import('./pages/contact-us'));
const PagePopup = lazy(() => import('./pages/popup'));
const PageWeb2Logs = lazy(() => import('./pages/web2Logs'));

const Routes = () => {
    const location = useLocation();

    // // console.debug(location);
    const roles = getCookie('user.roles');

    const pageVariants = {
        initial: {
            opacity: 0,
            scale: 0.99,
        },
        in: {
            opacity: 1,
            scale: 1,
        },
        out: {
            opacity: 0,
            scale: 1.01,
        },
    };

    const pageTransition = {
        type: 'tween',
        ease: 'anticipate',
        duration: 0.4,
    };

    const SuspenseLoading = () => {
        return (
            <>
                <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                    <div className="d-flex align-items-center flex-column px-4">
                        <ClimbingBoxLoader color={'#3c44b1'} loading={true} />
                    </div>
                    <div className="text-muted font-size-xl text-center pt-3">
                        Please wait while we load the live preview examples
                        <span className="font-size-lg d-block text-dark">
                            This live preview instance can be slower than a real production build!
                        </span>
                    </div>
                </div>
            </>
        );
    };

    // Define routes here
    const routeDefinitions = [
        { to: '/artists', component: PageArtist },
        { to: '/products', component: PageItem },
        { to: '/articles', component: PageArticle },
        { to: '/infanity-blogs', component: PageInfanityBlog },
        { to: '/categories', component: PageCategory },
        { to: '/featured', component: PageFeatured },
        { to: '/contact-us', component: PageContactUs },
        { to: '/popup', component: PagePopup },
        { to: '/logs', component: PageLogs },
        ,
    ];
    if (roles && roles.includes('super-admin')) {
        routeDefinitions.push({ to: '/web2logs', component: PageWeb2Logs });
    }

    return (
        <AnimatePresence>
            <Suspense fallback={<SuspenseLoading />}>
                <Switch>
                    <Route path={routeDefinitions.map((definition) => definition.to)}>
                        <LeftSidebar>
                            <Switch location={location} key={location.pathname}>
                                <motion.div
                                    initial="initial"
                                    animate="in"
                                    exit="out"
                                    variants={pageVariants}
                                    transition={pageTransition}
                                >
                                    {routeDefinitions.map((definition, index) => (
                                        <Route
                                            key={index}
                                            exact
                                            path={definition.to}
                                            component={RequireAuth(definition.component)}
                                        />
                                    ))}
                                </motion.div>
                            </Switch>
                        </LeftSidebar>
                    </Route>

                    <Route path={['/logout', '/login', '/recover', '/reset-password', '/error-404', '/error-500']}>
                        <MinimalLayout>
                            <Switch location={location} key={location.pathname}>
                                <motion.div
                                    initial="initial"
                                    animate="in"
                                    exit="out"
                                    variants={pageVariants}
                                    transition={pageTransition}
                                >
                                    <Route exact path="/logout" component={RequireAuth(PageLogout)} />
                                    <Route exact path="/login" component={RequireAuth(PageLogin)} />
                                    <Route exact path="/recover" component={RequireAuth(PageRecover)} />
                                    <Route path="/reset-password/:code" component={PageResetPassword} />
                                    <Route exact path="/error-404" component={RequireAuth(PageError404)} />
                                    <Route exact path="/error-500" component={RequireAuth(PageError500)} />
                                </motion.div>
                            </Switch>
                        </MinimalLayout>
                    </Route>
                    <Redirect from="/" to="/products" />
                </Switch>
            </Suspense>
        </AnimatePresence>
    );
};

export default Routes;
