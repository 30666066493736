import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Badge,
    ListGroup,
    ListGroupItem,
    Nav,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
} from 'reactstrap';
import { NavLink as NavLinkStrap } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import avatar from '../../assets/images/avatars/avatar5.png';
import { getCookie } from '../../libs/cookie';
import { publicUrl } from '../../config/env';

const HeaderUserbox = () => {
    const history = useHistory();
    const name = getCookie('user.name');
    const email = getCookie('user.email');

    return (
        <>
            <UncontrolledDropdown className="position-relative ml-2">
                <DropdownToggle color="link" className="p-0 text-left d-flex btn-transition-none align-items-center">
                    <div className="d-block p-0 avatar-icon-wrapper">
                        <Badge color="success" className="badge-circle p-top-a">
                            Online
                        </Badge>
                        <div className="avatar-icon rounded">
                            <img src={avatar} alt="..." />
                        </div>
                    </div>
                    <div className="d-none d-xl-block pl-2">
                        <div className="font-weight-bold">{name}</div>
                        <span className="text-black-50">{email}</span>
                    </div>
                    <span className="pl-1 pl-xl-3">
                        <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
                    </span>
                </DropdownToggle>
                <DropdownMenu right className="dropdown-menu-lg overflow-hidden p-0">
                    <ListGroup flush className="text-left bg-transparent">
                        <ListGroupItem className="rounded-top">
                            <Nav pills className="nav-neutral-primary flex-column">
                                {/* <NavItem className="nav-header d-flex text-primary pt-1 pb-2 font-weight-bold align-items-center">
                                    <div>Profile options</div>
                                    <div className="ml-auto font-size-xs">
                                        <a href="#/" onClick={(e) => e.preventDefault()} id="ChangeSettingsTooltip">
                                            <FontAwesomeIcon icon={['fas', 'plus-circle']} />
                                        </a>
                                        <UncontrolledTooltip target="ChangeSettingsTooltip" container="body">
                                            Change settings
                                        </UncontrolledTooltip>
                                    </div>
                                </NavItem>
                                <NavItem>
                                    <NavLinkStrap href="#/" onClick={(e) => e.preventDefault()}>
                                        My Account
                                    </NavLinkStrap>
                                </NavItem>
                                <NavItem>
                                    <NavLinkStrap href="#/" onClick={(e) => e.preventDefault()}>
                                        Profile settings
                                    </NavLinkStrap>
                                </NavItem>
                                <NavItem>
                                    <NavLinkStrap href="#/" onClick={(e) => e.preventDefault()}>
                                        Active tasks
                                    </NavLinkStrap>
                                </NavItem> */}
                                <NavItem>
                                    <NavLinkStrap href={`${publicUrl}/logout`}>Logout</NavLinkStrap>
                                </NavItem>
                            </Nav>
                        </ListGroupItem>
                    </ListGroup>
                </DropdownMenu>
            </UncontrolledDropdown>
        </>
    );
};

export default HeaderUserbox;
