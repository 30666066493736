import React from 'react';

import clsx from 'clsx';

import { connect } from 'react-redux';

const Footer = (props) => {
    const { footerShadow, footerBgTransparent } = props;
    return (
        <>
            <div
                className={clsx('app-footer text-black-50', {
                    'app-footer--shadow': footerShadow,
                    'app-footer--opacity-bg': footerBgTransparent,
                })}
            >
                <div className="app-footer--first"></div>
                <div className="app-footer--second">
                    <span>2022 © infanity by</span>
                    <a href="" target="_blank" rel="noopener noreferrer" title="">
                        {' infanity.'}
                    </a>
                </div>
            </div>
        </>
    );
};
const mapStateToProps = (state) => ({
    footerShadow: state.ThemeOptions.footerShadow,
    footerBgTransparent: state.ThemeOptions.footerBgTransparent,
});

export default connect(mapStateToProps)(Footer);
